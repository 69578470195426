<template>
  <!-- 新 - 首页 - 课程中心 -->
  <div class="courseCenter gotop">
    <headPageNew activeName="2"></headPageNew>
    <div class="banner"></div>
    <div class="couserCenterTop">
      <div class="couserCenterTopInner clearfix">
        <div class="couserCenterTopLeft">
          <el-tabs v-model="typeId" @tab-click="clickFirst" v-if="ftype.length>0">
            <el-tab-pane label="不限" name="all" key="all"></el-tab-pane>
<!--            <el-tab-pane label="不限1" name="1"></el-tab-pane>-->
<!--            <el-tab-pane label="不限2" name="2"></el-tab-pane>-->
<!--            <el-tab-pane label="不限3" name="3"></el-tab-pane>-->
<!--            <el-tab-pane label="不限4" name="4"></el-tab-pane>-->
<!--            <el-tab-pane label="不限5" name="5"></el-tab-pane>-->
<!--            <el-tab-pane label="不限6" name="6"></el-tab-pane>-->
<!--            <el-tab-pane label="不限7" name="7"></el-tab-pane>-->
<!--            <el-tab-pane label="不限8" name="8"></el-tab-pane>-->
<!--            <el-tab-pane label="不限9" name="9"></el-tab-pane>-->
<!--            <el-tab-pane label="不限10" name="10"></el-tab-pane>-->
<!--            <el-tab-pane label="不限11" name="11"></el-tab-pane>-->
<!--            <el-tab-pane label="不限12" name="12"></el-tab-pane>-->
<!--            <el-tab-pane label="不限13" name="13"></el-tab-pane>-->
<!--            <el-tab-pane label="不限14" name="14"></el-tab-pane>-->
<!--            <el-tab-pane label="不限15" name="15"></el-tab-pane>-->
            <el-tab-pane :label="item.name" :name="item.courseTypeId" v-for="(item,index) in ftype" :key="index">
<!--              <el-radio-group v-model="secondTypeId" v-if="typeId!='all'&&item.children.length>0" @change="changeSecond">-->
<!--                <el-radio-button label="all">不限</el-radio-button>-->
<!--                <el-radio-button :label="itemInner.courseTypeId" v-for="(itemInner, indexInner) in item.children" :key="indexInner">-->
<!--                  {{ itemInner.name }}-->
<!--                </el-radio-button>-->
<!--              </el-radio-group>-->
            </el-tab-pane>

          </el-tabs>
        </div>
        <div class="couserCenterTopRight">
          <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="courseName"
              clearable>
          </el-input>
          <el-button type="primary" @click="getListData(1,25)">搜索</el-button>
        </div>

        <div>

      </div>

      </div>
    </div>
    <div class="couserCenterBottom " v-if="typeId!='all'">
      <div class="couserCenterTopInner clearfix">
        <p @click="changeSecond('all','不限')" :class="secondTypeId == 'all' ? 'active' : ''">
          不限
        </p>
        <template v-if="children.length>0">
          <p v-for="(item, index) in children" :key="index" :class="item.courseTypeId == secondTypeId ? 'active' : ''" @click="changeSecond(item.courseTypeId)">
            {{ item.name }}
          </p>
        </template>

<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->
<!--        <p>测试文案</p>-->

      </div>

      <!-- </div> -->
    </div>
    <div class="main">

      <div class="courseBigBox">
        <div class="courseCenterBox">
          <div class="courseBox">
            <div v-for="(item, index) in courseList" :key="index" class=""  @click="courseDetail(item,index)">
              <img :src="item.logo" alt="" />
              <p class="inNovate_subTitle">
                {{ item.courseName }}
              </p>
              <div
                  style="
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-top:14px;
              "
              >
<!--                <div class="courseDetail">-->
<!--&lt;!&ndash;                <span&ndash;&gt;-->
<!--&lt;!&ndash;                >章节: <span>共{{ item.coursewareNum }}节</span></span&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--                </div>-->
                <div class="courseDetailBtn">

                  <span
                  ><span>共{{ item.coursewareNum }}节</span></span
                  >
                  <span>{{ tranNumber(item.studyNum, 2) }}人想学</span>
<!--                  <el-button size="mini" @click="courseDetail(item)"-->
<!--                  >详情</el-button-->
<!--                  >-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 1168px; margin: 0 auto">
          <el-pagination
              style="clear: both; text-align: right; padding: 30px 20px 10px;"
              background
              layout="total, sizes,prev, pager, next,jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              popper-class="popper"
              :popper-append-to-body="false"
              :current-page="fenYe.currentPage"
              :page-sizes="[25]"
              :page-size="fenYe.size"
              :total="fenYe.total"
              v-show="fenYe.total > 0"
          ></el-pagination>
        </div>
      </div>
    </div>
    <bottomPage />
    <hz/>
  </div>
</template>
<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";

import bottomPage from "./bottomPage.vue";

import hz from "../wrongAuthenticatePage/hz.vue";
export default {
  name: "wrongAuthenticatePage_courseCenter",
  components: {
    headPageNew,
    bottomPage,
    hz
  },
  data() {
    return {
      typeId: 'all',
      secondTypeId: 'all',



      // 课程名称
      courseName: "",
      // 课程分类
      ftype: [],
      //课程分类二级项
      children: [],
      // 课程列表
      courseList: [],
      // 默认选中的课程分类的下标
      courseTypeId: 0,
      courseTypeName: "",
      //默认一级项选中二级项值
      secondTypeName: "",
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 25,
        total: null, // 共多少页
      },
    };
  },
  created() {
    this.getFtype();
    setTimeout(() => {
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    }, 500);
},
  computed: {},
  methods: {
    // 点击第一级分类
    clickFirst(){
      let _this = this
      this.secondTypeId = 'all'
      this.secondTypeName = '不限'
      if(this.typeId == 'all'){
        this.children = []
      }else{
        _this.children = []
        _this.ftype.forEach((e,i)=>{
          if(_this.typeId == e.courseTypeId){
            topNum = i + 2
            contentName = e.name
            if(e.children&&e.children.length){
              _this.children = e.children
            }

          }
        })
      }
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);


      //埋点所需
      let topNum = 0
      let contentName = ''
      let contentId = this.typeId
      let positionCode = 'CATEGORY_LEVEL1'
      if(this.typeId == 'all'){
        topNum = 1
        contentName = '不限'
        contentId = ''

      }else{
        this.ftype.forEach((e,i)=>{
          if(this.typeId == e.courseTypeId){
              topNum = i + 2
              contentName = e.name
          }
        })
        // console.log(this.ftype,this.ftype.find(item => item.courseTypeId == this.typeId))
        // contentName = this.ftype.find(item => item.courseTypeId == this.typeId).name
      }
      this.recordBuriedPoints(topNum,contentName,contentId,positionCode)

    },
    // 点击第二级分类
    changeSecond(courseTypeId,courseTypeName){
      this.secondTypeId = courseTypeId
      this.secondTypeName = courseTypeName
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);


      //埋点所需
      let topNum = 0
      let contentName = ''
      let contentId = this.secondTypeId
      let positionCode = 'CATEGORY_LEVEL2'
      if(this.secondTypeId == 'all'){
        topNum = 1
        contentName = '不限'
        contentId = ''
      }else{
        this.ftype.forEach((e,i)=>{
          if(this.typeId == e.courseTypeId){
            e.children.forEach((eInner,iInner)=>{
              if(this.secondTypeId == eInner.courseTypeId){
                topNum = iInner + 2
                contentName = eInner.name
              }
            })
          }
        })
      }
      this.recordBuriedPoints(topNum,contentName,contentId,positionCode)
    },
    //记录埋点
    recordBuriedPoints(topNum,contentName,contentId,positionCode){
      let gatherInfoParams = {
        contentId,// 内容的系统id
        contentName,// 内容名称
        moduleCode: 'COURSE',
        /* 模块标识

          HOME - 首页,
          COURSE - 课程推广页,
          DIGITAL_SERVICE - 数字服务,
          BUSINESS_COOPERATION - 商务合作

        */
        positionCode,
        /* 位置标识
          【PC_HR - HOME】
            NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
            INTELLIGENT_TOOLS - 智能化工具
            HR_PATTERN  - 人力资源新模式]

          【PC_HR - COURSE】
            CATEGORY - 分类
            COURSE - 课程
        */
        topNum// 顺序编号
      }
      console.log(gatherInfoParams)
      this.$gatherInfo(gatherInfoParams)
    },
    // 获取课程分类
    getFtype() {
      this.$post("/homepage/courseCategory", {}, 3000, false, 2).then((res) => {
        this.ftype = res.data || [];
        console.log('ftype',res.data)
        // this.clickType(this.json);
      });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 按课程分类 - 获取课程列表
    getListData(page, pagesize) {
      let data = {
        pageNum: page,
        pageSize: pagesize,
      };
      if (this.typeId&&this.typeId!='all') {
        data.courseType = this.typeId;
      }
      if (this.secondTypeId&&this.secondTypeId!='all') {
        data.courseTypeTwo = this.secondTypeId;
      }
      if(this.courseName){
        data.courseName = this.courseName;
      }
      this.$post("/homepage/course/all", data, 3000, false, 2).then((res) => {
        this.fenYe.total = res.data.total;
        this.courseList = res.data.list || [];
      });
    },

    //详情
    courseDetail(item,index) {
      // 点击详情记录埋点
      let gatherInfoParams = {
        contentId: item.courseId,// 内容的系统id
        contentName: item.courseName,// 内容名称
        moduleCode: 'COURSE',
        /* 模块标识

          HOME - 首页,
          COURSE - 课程推广页,
          DIGITAL_SERVICE - 数字服务,
          BUSINESS_COOPERATION - 商务合作

        */
        positionCode: 'COURSE',
        /* 位置标识
          【PC_HR - HOME】
            NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
            INTELLIGENT_TOOLS - 智能化工具
            HR_PATTERN  - 人力资源新模式]

          【PC_HR - COURSE】
            CATEGORY - 分类
            COURSE - 课程
        */
        topNum: index + 1// 顺序编号
      }

      this.$gatherInfo(gatherInfoParams)

      // 跳转到详情

      let CountEvent = window.JAnalyticsInterface.Event.CountEvent;
      let cEvent = new CountEvent("curriculumInfoNum");
      cEvent.addKeyValue("courseId", item.courseId).addKeyValue("courseType", item.courseType).addKeyValue("课程名称", item.courseName);
      window.JAnalyticsInterface.onEvent(cEvent);
      this.$router.push({
        path: "/wrongAuthenticatePage/courseDetail",
        query: {
          courseId: item.courseId,
          courseType: item.courseType,
          courseTypeId: this.typeId,
          courseTypeName: this.courseTypeName,
          courseTypeTwoName: this.secondTypeName,
        },
      });
    },
    //转换10000-万
    tranNumber(num, point) {
      let numStr = num.toString();
      // 一万以内直接返回
      if (numStr.length < 5) {
        return numStr;
      } else if (numStr.length > 4) {
        let decimal = numStr.substring(
          numStr.length - 4,
          numStr.length - 4 + point
        );
        return parseFloat(parseInt(num / 10000) + "." + decimal) + "万";
      }
    },
  },
};
</script>
<style lang="less" scoped>

.courseCenter{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner1.png");
}
.main{
  width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 50;
  //background: #000;
}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
.couserCenterTop{
  position: relative;
  width: 100%;
  background: #fff;
  //&:before{
  //  content: '';
  //  position: absolute;
  //
  //  width: 100%;
  //  height: 73px;
  //  background-color: #fff;
  //}
  .couserCenterTopInner{
    width: 1440px;
    margin: 0 auto;
    /deep/.el-tabs__header{
      margin: 0;
    }
    /deep/.el-tabs__item{
      height: 33px;
      line-height: 33px;
      transition: 0.5s   ;
      padding: 0 20px!important;
    }
    /deep/.el-tabs__nav-wrap::after{
      background-color: transparent;
    }
    /deep/.el-tabs__active-bar{
      background-color: transparent;
      &::after{
        display: none;
        content: '';
        //position: absolute;
        //bottom: 0;
        //left: 50%;
        //transform: translateX(-60%);
        //width: 60%;
        //height: 2px;
        //background-color: #2878FF;
      }
    }
    /deep/.el-tabs__nav{
      white-space: unset;
    }
    /deep/.el-tabs__nav-wrap.is-scrollable{
      padding: 0;
    }
    /deep/.el-tabs__nav-next, /deep/.el-tabs__nav-prev{
      line-height: 73px;
      display: none;
    }
    .couserCenterTopLeft{
      margin-left: -20px;
      padding: 20px 0;
      float: left;
      width: calc(100% - 360px);
      /deep/.el-radio-button__inner{
        background-color: transparent;
        border: transparent;
        box-shadow: none;
      }
      /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: transparent;
        border: transparent;
        color: #2878FF;
      }
      /deep/.el-radio-button__inner{
        padding: 0 20px!important;
        //padding-left: 0;
        //padding-right: 40px;
      }
      /deep/.el-tabs__item:hover{
        color: #2878FF;
      }
      /deep/.el-tabs__item.is-active{
        color: #2878FF;
      }
      /deep/.el-radio-button__inner:hover{
        color: #2878FF;
      }
    }
    .couserCenterTopRight{
      margin-top: 16px;
      position: relative;
      float: right;
      width: 310px;
      display: flex;
      background-color: #F9FAFC;
      //background-color: #000;
      border-radius: 8px;
      /deep/.el-input__inner{
        height: 42px;
        line-height: 42px;
        border: 0;
        background-color: transparent;
      }
      /deep/.el-input__icon{
        line-height: 42px;
      }
      /deep/.el-button--primary{
        padding: 0 17px;
        width: 64px;
        height: 42px;
        background-color: #2878FF;
      }
    }
  }
}
.couserCenterBottom{
  .couserCenterTopInner{
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 0;
    p{
      font-size: 14px;
      line-height: 32px;
      padding-right: 40px;
      cursor: pointer;
      &.active{
        color: #2878FF;
      }
    }

  }
}
/deep/.el-select-dropdown__item.selected{
  color: #2878FF;
}
.courseBigBox {
  background: #f9f9f9;
  padding: 0 0 30px;
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #2878FF;
  }
  /deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
    border-color: #2878FF;
  }

  .courseCenterBox {
    width: 1440px;
    margin: 12px auto 0;

    .courseBox {
      display: flex;
      flex-wrap: wrap;
      > div {
        border-radius: 8px;
        width: 272px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 30px;
        background: #fff;
        transition: 0.5s;
        &:nth-child(5n){
          margin-right: 0;
        }
        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 30px 1px rgba(40,120,255,0.1);
        }
        .courseDetailBtn {
          padding: 0 20px 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #999;
          }
          button {
            background: #fff;
            color: #5393ff;
            border-color: #5393ff;
          }
        }
      }
      img {
        width: 100%;
        border-radius: 8px 8px 0 0;
        height: 153px;
      }
      p {
        text-align: left;
        color: #333;
        font-size: 16px;
        padding: 16px 20px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 1.4;
      }
      span {
        font-size: .8rem;
      }
    }
  }
}
@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }
  .couserCenterTop .couserCenterTopInner{
    width: 1200px;
  }
  .couserCenterBottom .couserCenterTopInner{
    width: 1200px;
  }
  .courseBigBox .courseCenterBox{
    width: 1200px;
  }
  .courseBigBox .courseCenterBox .courseBox > div {
    width: 224px;
  }
  .courseBigBox .courseCenterBox .courseBox img{
    height: 126px;
  }
}



</style>
